import React from "react";
import Navbar from "../Reusable/Navbar/Navbar";
import "./InstructionScreen.css";
import error from "../../assets/error.svg";
import { useNavigate } from "react-router";

const InstructionScreen = () => {
  // const [startInterviewBtnDisable, setStartInterviewBtnDisable] = useState(true);
  const navigate = useNavigate();

  // useEffect(() => {
  //   setTimeout(() => {
  //     setStartInterviewBtnDisable(false);
  //   }, 10000)
  // }, [])

  return (
    <div>
      <Navbar />
      <div className="instruction-main-container">
        <div className="instruction-inner-container px-4">
          <div className="read-instruction-container">
            <img src={error} alt="error" />
            <div className="read-instruction-text">
              Read the instructions carefully before starting the interview
            </div>
          </div>

          <div className="instructions-container">
            <div>
              1. Ensure your equipment is working properly and that your internet connection is strong enough for seamless video uploads.{" "}
            </div>
            <div>
              2. Make sure your laptop is capable of smooth video recording without interruptions.
            </div>
            <div>
              3. Find a quiet, well-lit location with a professional background.{" "}
            </div>
            <div>4. Dress professionally</div>
            <div>
              5. Remove clutter and display relevant documents within reach.{" "}
            </div>
            <div>
              6. Prepare and practice concise answers to common interview
              questions.{" "}
            </div>
            <div>
              7. Take your time and pause briefly before recording each
              response.
            </div>
            <div>8. Maintain eye contact with the camera while speaking.</div>
            <div>9. Use confident body language and natural hand gestures.</div>
            <div>
              10. Before starting the interview, please keep in mind that you
              cannot answer the previous question.
            </div>
            <div>
              11. Please exercise caution when responding to the upcoming
              questions.
            </div>
          </div>

          <div className="instruction-btn-container">
            <button
              className="start-interview-btn-enabled"
              style={{
                border: "none",
                background: "#33333380 0% 0% no-repeat padding-box",
                color: "white",
                borderRadius: "40px 8px 8px 40px",
              }}
              // disabled={startInterviewBtnDisable ? true : false}
              onClick={() => navigate("/interview/questions")}
            >
              <span className="start-interview-btn">
                <span className="start-interview-btn-circle">
                  <span className="start-interview-btn-dot-enabled start-interview-btn-dot"></span>
                </span>
                <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                  Start Interview
                </span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructionScreen;
