import React, { useEffect, useState } from "react";
import Login from "./Login";
import LicenceAgreementScreen from "../LicenseAgreementScreen/LicenseAgreementScreen";
import { API } from "../../global";
import { useParams } from 'react-router-dom';
import { customTheme } from "../../CustomStyle/CustomTheme";


const LandingPage = () => {
  const { tenantId ,testid} = useParams();
  const [showLicense, setShowLicense] = useState(false);
  const [licenseContent,setLicenseContent] = useState(null)
  useEffect(() => {
    localStorage.setItem('tenantId',tenantId)
    localStorage.removeItem('showLicense')
    localStorage.setItem('testId',testid)
     const showLicensePage =  JSON.parse(localStorage.getItem('showLicense'))
     console.log("showLicensePage",showLicensePage)
     setShowLicense(showLicensePage)
    customTheme()
    
    fetch(`${API}/candidate/tenant/get-tenant-details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body:JSON.stringify({interview_id : testid})
    })
      .then((res) => res.json())
      .then((response) => {
        if(response.success){
            const theme = response.result.tenant_theme_settings;
            const interviewData = response.result.tenant_interview_settings
            localStorage.setItem('waitingDuration',interviewData.max_wait_duration_after_qn_read)
            localStorage.setItem('faceDetection',interviewData.face_detection)
            localStorage.setItem('tabDetection',interviewData.fraud_detection)
            localStorage.setItem('themeData',JSON.stringify(theme))
            localStorage.setItem("logoUrl", theme.logo_url);
            localStorage.setItem("tenant_id", response.result._id);
            setLicenseContent(response.result.tenant_policy_documents)
        }
       
      });

      // eslint-disable-next-line
  }, []);

  const handleShowLicense = () => {
    setShowLicense(true);
    localStorage.setItem('showLicense',true)
  };

  return (
    <div>
      {showLicense ? (
        <LicenceAgreementScreen licenseContent={licenseContent}/>
      ) : (
        <Login setShowLicense={handleShowLicense} />
      )}
    </div>
  );
};

export default LandingPage;
