import React, { useEffect } from "react";
import "./ThankYouScreen.css";
import Navbar from "../../components/Reusable/Navbar/Navbar";
import thumb from "../../assets/talentio_thump.png";
import { useNavigate } from "react-router";
// import { customTheme } from '../../CustomStyle/CustomTheme'

export default function ThankYouScreen({ mediaStream }) {
  // console.log(props);
  // console.log(props.stream);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("faceDetection");
    localStorage.removeItem("tabDetection");
    localStorage.removeItem("showLicense");
    // customTheme()
    if (
      localStorage.getItem("cameraoff") === null ||
      localStorage.getItem("cameraoff") === undefined
    ) {
      localStorage.setItem("cameraoff", "removecamera");
      window.location.reload();
      localStorage.removeItem("testId");
      localStorage.removeItem("tenantId");
      localStorage.removeItem('uploadQueue')
      localStorage.removeItem('uploadSizeToBeUpload')
      localStorage.removeItem('uploadSizeUploaded')
    }

    sessionStorage.clear();
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Required for Chrome
    };

    const handlePopstate = () => {
      navigate(window.location.pathname); // Navigate back to the current page
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopstate);
    };

    // eslint-disable-next-line
  }, [navigate]);

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row">
          <div
            className="col-lg-12"
            style={{
              display: "flex",
              justifyContent: "center",
              height: "90vh",
              alignItems: "center",
            }}
          >
            <div className="thankyou-screen-container">
              <div className="panel-heading" style={{ textAlign: "center" }}>
                <img
                  src={thumb}
                  className=""
                  alt="talentio_thump"
                  style={{ marginBottom: 20, width: "15%" }}
                />
                <h3 className="panel-title">
                  Thank you for attending the interview!
                </h3>
                <p style={{ marginTop: 20 }}>
                  We appreciate your time and effort in completing the video
                  interview. Your responses have been recorded and will be
                  considered in the evaluation process. We will be in touch with
                  you regarding the next steps.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
