import React, { useEffect, useState, useRef } from "react";
import Navbar from "../Reusable/Navbar/Navbar";
import ProgressBar from "react-bootstrap/ProgressBar";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { API } from "../../global";
import { Interweave } from "interweave";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";

const QuestionScreen = () => {
  const navigate = useNavigate();

  const [speaking, setSpeaking] = useState(false);
  const [questionDetails, setQuestionDetails] = useState(null);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [showTimer, setShowTimer] = useState(false);
  const [interviewTimer, setInterviewTimer] = useState(0);
  const [progressTime, setProgressTime] = useState(100);
  // const waitingTime = JSON.parse(localStorage.getItem("waitingDuration"));
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  let qNo = searchParams.get("qno");
  qNo = qNo ? qNo : 1;

  const currentQuestionNo = useRef(0);
  const currentQuestionId = useRef();

  const synth = window.speechSynthesis;

  useEffect(() => {
    getQuestion();
    setTimer();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (interviewTimer < 0) {
      startInterview();
    }

    //eslint-disable-next-line
  }, [interviewTimer]);

  const setTimer = () => {
    let waitingTime = JSON.parse(localStorage.getItem("waitingDuration"));
    if (waitingTime) {
      setInterviewTimer(waitingTime);
    }
  };

  const isCode = (text) => {
    // Define some heuristics for distinguishing between code and natural language
    const codeIndicators = [
      // Common code-related characters
      "{",
      "}",
      "(",
      ")",
      "[",
      "]",
      "=",
      ";",
      ",",
      ".",
      "+",
      "-",
      "*",
      "/",
      // Common programming keywords
      "function",
      "var",
      "let",
      "const",
      "if",
      "else",
      "for",
      "while",
      "do",
      "switch",
      "case",
      "break",
      "return",
      // Common programming operators
      "==",
      "===",
      "!=",
      "!==",
      ">",
      ">=",
      "<",
      "<=",
      "&&",
      "||",
      "!",
      "=>",
    ];

    // Count the occurrences of code indicators in the text
    let codeIndicatorCount = 0;
    codeIndicators.forEach((indicator) => {
      codeIndicatorCount += text.split(indicator).length - 1;
    });

    // Heuristic: If more than 5% of the characters are code indicators, it's likely code
    return codeIndicatorCount / text.length > 0.05;
  };

  const getQuestion = async () => {
    let interview_question_ids = JSON.parse(
      sessionStorage.getItem("interview_question_ids")
    );
    let totalQuestions = JSON.parse(sessionStorage.getItem("total_question"));
    setTotalQuestions(totalQuestions);
    if (interview_question_ids?.length > 0) {
      const current_question_id = interview_question_ids[Number(qNo) - 1];
      currentQuestionId.current = current_question_id?._id;
      currentQuestionNo.current = currentQuestionNo.current + 1;
      getQuestionDetails();
    }
  };

  function htmlToPlainText(html) {
    var tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  }

  const getQuestionDetails = async () => {
    const data = {
      question_id: currentQuestionId.current,
    };

    // Convert the data object to a query string

    await fetch(`${API}/candidate/interview/get-question`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${sessionStorage.getItem("Token_candi")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          const isCodeData = isCode(htmlToPlainText(data.result.question));
          if (!isCodeData) {
            setTimeout(() => {
              speakText(data.result);
            }, 1000);
          } else {
            setTimeout(() => {
              setShowTimer(true);
              startInterviewTime();
            }, 15000);
          }

          setQuestionDetails(data.result);
        }
      });
  };

  const startInterview = () => {
    navigate(`/interview/interview?qno=${qNo}`);
    synth.cancel();
  };

  const getDefaultVoice = () => {
    // Check for browser support
    if ("speechSynthesis" in window) {
      // Get all available voices
      const voices = speechSynthesis.getVoices();

      // Determine the default voice based on the userAgent
      const userAgent = navigator.userAgent;

      // Define fallback voices for different platforms
      const fallbackVoices = {
        Win: "Microsoft Zira - English (United States)",
        Mac: "Alex",
        // Add fallback voices for other platforms if needed
      };

      // Find the appropriate voice based on the platform
      let defaultVoice = null;
      const platform = Object.keys(fallbackVoices).find((platform) =>
        userAgent.includes(platform)
      );
      if (platform) {
        const desiredVoiceName = fallbackVoices[platform];
        defaultVoice = voices.find((voice) => voice.name === desiredVoiceName);
      }

      // If the desired voice is not available, select a suitable alternative
      if (!defaultVoice) {
        // Example: Select any English voice as a fallback
        defaultVoice = voices.find((voice) => voice.lang.startsWith("en"));
      }

      return defaultVoice;
    } else {
      console.error("Speech synthesis not supported");
      return null;
    }
  };

  const convertToText = (html) => {
    let doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const speakText = (question) => {
    if (synth && !speaking) {
      const plainText = convertToText(question?.question);
      const utterance = new SpeechSynthesisUtterance(plainText);

      // Get a list of voices
      // const voices = synth.getVoices();

      // Find the voice you want to use
      const desiredVoice = getDefaultVoice();
      if (desiredVoice) {
        // Set the desired voice
        utterance.voice = desiredVoice;
        utterance.volume = 3;

        // Speak the text
        synth.speak(utterance);

        utterance.onend = () => {
          setSpeaking(false);
          setShowTimer(true);
          startInterviewTime();
        };
      } else {
        console.error("Desired voice not found");
      }
    }
  };

  const startInterviewTime = () => {
    const timerData = JSON.parse(localStorage.getItem("waitingDuration"));
    if (timerData > 0) {
      const timeoutFunction = setInterval(() => {
        if (timerData > 0) {
          setInterviewTimer((prevInterviewTimer) => prevInterviewTimer - 1);
          let time = 100 / timerData;
          setProgressTime((prevProgressTime) => prevProgressTime - time);
        } else {
          clearInterval(timeoutFunction); // Clear the interval
          startInterview();
        }
      }, 1000);
      return () => clearInterval(timeoutFunction);
    }
  };

  return (
    <div>
      <>
        <Navbar />
        <Container fluid>
          <Row className="d-flex justify-content-center align-items-center">
            <Col className="ques-reading-container mt-4">
              <div className="ques-reading-card">
                <Row>
                  <Col className="d-flex justify-content-center">
                    <div
                      className="panel-heading text-center"
                      style={{ marginLeft: "8em" }}
                    >
                      <h5 className="panel-title">
                        Question {qNo} of {totalQuestions}
                      </h5>

                      <progress id="question" value={qNo} max={totalQuestions}>
                        {" "}
                        {`${qNo} %`}
                      </progress>
                    </div>
                  </Col>
                  <Col xs={2} className="d-flex justify-content-end"></Col>
                </Row>

                <div className="ques-reading-body">
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "bolder",
                      fontSize: "20px",
                    }}
                    className="question-show-container"
                  >
                    <Interweave content={questionDetails?.question} />
                  </div>

                  <Row style={{ marginLeft: "130px", marginRight: "10px" }}>
                    <Col
                      xs={10}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <button
                        onClick={() => startInterview()}
                        className="test-screen-btn start-answering-btn"
                      >
                        Start Answering
                      </button>
                    </Col>
                    {showTimer && (
                      <>
                        <Col
                          xs={2}
                          className="d-flex justify-content-end align-items-center"
                        >
                          <div
                            style={{
                              height: 40,
                              margin: 5,

                              color: "black",
                              borderRadius: 3,
                              width: "210px",
                            }}
                          >
                            <span
                              style={{
                                padding: "0px 15px 15px 15px",
                                lineHeight: "2.6",
                              }}
                            >
                              Interview Starts in {interviewTimer} sec
                            </span>

                            <ProgressBar
                              variant="danger"
                              animated
                              now={progressTime}
                            />
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    </div>
  );
};

export default QuestionScreen;
